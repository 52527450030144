
import { api } from "@/services/interceptors";

export default {
  state: {
    ordersAlerts: null,
    currentOrderAlert: {
        buy: 0,
        sell: 0,
        giver: 0,
        taker: 0
    }
  },
  getters: {
    getOrdersAlerts(state) {
      return state.ordersAlerts;
    },
    getCurrentOrdersAlerts(state) {
        return state.currentOrderAlert;
      },
  },
  actions: {
    allOrdersAlertsList({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/auth/orders-alerts').then((res) => {                
                commit('SET_ORDERS_ALERTS', res.data.ordersAlerts);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    createOrderAlertAction({ commit }, payload) {
        commit('STORE_NOTHING');
        return new Promise((resolve, reject) => {
            api.post('api/auth/orders-alerts', payload).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    getOrderAlertLinkToProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.get('api/auth/orders-alerts/product/' + payload).then((res) => {                
                commit('SET_CURRENT_ORDERS_ALERTS', res.data.alertsLinkToProduct);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    updateOrderAlertAction({ commit }, payload) {
        commit('STORE_NOTHING');
        return new Promise((resolve, reject) => {
            api.put('api/auth/orders-alerts', payload).then(() => {
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
  },
  mutations: {
    SET_ORDERS_ALERTS(currentState, ordersAlerts) {
      currentState.ordersAlerts = ordersAlerts;
    },
    SET_CURRENT_ORDERS_ALERTS(currentState, current) {
        currentState.currentOrderAlert = current;
      },
    STORE_NOTHING() {},
  },
};
