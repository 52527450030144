import { ElMessage, ElLoading, ElNotification } from 'element-plus';
import router from '../router/index';
import axios from 'axios';
import i18n from '../i18n';
import m from 'moment';

export default {
  state: {
    register_step: 1,
    forgot_password: 0,
    two_fa: 0,
    auth: null,
    kyc_details: null
  },
  getters: {
    getIsAuth(state) {
      return state.auth;
    },
    kycState(state) {
      return state.kyc_details;
    },
    registerStep(state) {
      return state.register_step-1;
    }
  },
  actions: {
    register({ commit, dispatch }, payload) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      axios.post(process.env.VUE_APP_BASE_URL + 'api/register', payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          localStorage.setItem('loginAt', m().format('YYYY-MM-DD HH:mm:ss'));
          
          ElMessage({
            message: res.data.message,
            type: 'success',
            grouping: true
          });
          loading.close();
                    
          if (res.data.token) {
            commit('STORAGE_TOKEN', res.data.token);
            commit('SET_AUTH', true);
          }
                    
          dispatch('authverification');
        })
        .catch((e) => {
          commit('SET_AUTH', false);
          ElMessage({
            message: e.response.data.errors ? i18n.global.t('commons.check_inputs') + e.response.data.errors : e.response.data.message ? e.response.data.message : i18n.global.t('commons.generic_error'),
            type: 'error',
            grouping: true,
            duration: 6000
          });
          loading.close();
          if (e.response.data.is_register) router.push('/login');
        });
    },
    login({ commit, dispatch }, payload) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      axios.post(process.env.VUE_APP_BASE_URL + 'api/login', payload.login, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          loading.close();

          
          if (res.data.token && !res.data.twofa || res.data.login_blocked_by && !res.data.twofa) {
            ElMessage({
              message: res.data.message,
              type: 'success',
              grouping: true
            });
            
            commit('STORAGE_TOKEN', res.data.token);
            commit('SET_AUTH', true);
            dispatch('authverification');
            localStorage.setItem('loginAt', m().format('YYYY-MM-DD HH:mm:ss'));
                        
            if (!res.data.login_blocked_by) {
              router.push('/');
            }

            if (res.data.login_blocked_by) {
              switch (res.data.login_blocked_by) {
              case 2: router.push('/register'); commit('CHANGE_REGISTER_STEP', 2);
                break;
              case 3: router.push('/register'); commit('CHANGE_REGISTER_STEP', 3);
                break;
              case 4: router.push('/register'); commit('CHANGE_REGISTER_STEP', 4);
                break;
              case 5: router.push('/register'); commit('CHANGE_REGISTER_STEP', 5);
                break;
              default: router.push('/');
                break;
              }
            }
          } else if (res.data.twofa) {
            commit('STORAGE_TOKEN', res.data.token);
            commit('SET_AUTH', false);

            commit('CHANGE_2FA_STEP', 1);

            ElNotification({
              title: i18n.global.t('commons.code_send'),
              message: i18n.global.t('commons.code_time_available'),
              type: 'warning',
              duration: 10000
            });
          }

          if (payload.remember_me) {
            const remember_me = { set: Date.now(), username: payload.login.email };
            localStorage.setItem('remember_me', JSON.stringify(remember_me));
          }

          if (!res.data.twofa) {
            dispatch('getLists');
            dispatch('getAccountDetails');
          }

        })
        .catch((e) => {
          loading.close();
          commit('SET_AUTH', false);
          ElMessage({
            message: e.response.data.errors ? i18n.global.t('commons.check_inputs') + e.response.data.errors : e.response.data.message ? e.response.data.message : i18n.global.t('commons.generic_error'),
            type: 'error',
            grouping: true,
            duration: 6000
          });
        });
    },
    logout({ commit }) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL + 'api/auth/logout', { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    sendCode2FA({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL + 'api/auth/2fa', payload, { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
          .then((res) => {
            if (res.data.token) {
              commit('STORAGE_TOKEN', res.data.token);
            }
            commit('SET_AUTH', true);
            dispatch('authverification');
            router.push('/search-products');
            commit('CHANGE_2FA_STEP', 0);
            resolve();
          })
          .catch((e) => {
            ElMessage({
              message: e.response.data.message ? e.response.data.message : i18n.global.t('commons.generic_error'),
              type: 'error',
              grouping: true,
              duration: 6000
            });
            reject();
          });
      });
    },
    sendEmailCodeResetPass({ commit }, payload) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      axios.post(process.env.VUE_APP_BASE_URL + 'api/reset/password/email', payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          commit('SET_FORGOT_PASSWORD', 2);
          ElMessage({
            message: res.data.message,
            type: 'success',
            grouping: true
          });
          setTimeout(() => {
            loading.close();
          }, 300);
        })
        .catch(() => {
          loading.close();
        });
    },
    resetPassword({ commit }, payload) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      axios.post(process.env.VUE_APP_BASE_URL + 'api/reset/password', payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          commit('SET_FORGOT_PASSWORD', 0);
          loading.close();
          ElMessage({
            message: res.data.message,
            type: 'success',
            grouping: true
          });
        })
        .catch((e) => {
          loading.close();
          ElMessage({
            message: e.response.data.errors ? i18n.global.t('commons.check_inputs') + e.response.data.errors : e.response.data.message ? e.response.data.message : i18n.global.t('commons.generic_error'),
            type: 'error',
            grouping: true,
            duration: 6000
          });
        });
    },
    authverification({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BASE_URL + 'api/auth/verification', { headers: { 'Content-Type': 'application/json', authorization: 'Bearer ' + (localStorage.getItem('token')) } })
          .then((res) => {
            resolve(res.data.is_auth);
            if (res.data.is_auth) {
              commit('SET_AUTH', res.data.is_auth);
              commit('CHANGE_REGISTER_STEP', res.data.step);
              commit('SET_KYC_DETAILS', res.data);
            }
          })
          .catch((e) => {
            commit('SET_AUTH', false);
            localStorage.removeItem('token');
            reject(e.response.data.message);
          });
      });
    }   
  },
  mutations: {
    CHANGE_REGISTER_STEP(currentState, step) {
      currentState.register_step = step;
    },
    SET_KYC_DETAILS(currentState, data) {
      currentState.kyc_details = data;
    },
    CHANGE_2FA_STEP(currentState, step) {
      currentState.two_fa = step;
    },
    SET_FORGOT_PASSWORD(currentState, forgot) {
      currentState.forgot_password = forgot;
    },
    STORAGE_TOKEN(currentState, token) {
      // console.log(currentState);

      if (process.env.NODE_ENV !== 'production') {
        console.warn('Set token in LS - dev/preprod mode');
        localStorage.setItem('token', token);
      }
    },
    SET_AUTH(currentState, state) {
      currentState.auth = state;
    },
    STORE_NOTHING() { }
  }
};