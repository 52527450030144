<template>
  <div class="appbody">
    <nav id="nav">
      <TheNavigationVue
        :darkmode="darkmode"
        :menuAlerts="menuAlerts"
        @menu-state-alerts="openMenuAlerts"
      >
      </TheNavigationVue>
    </nav>

    <el-dialog
      v-model="isFirstTime"
      title=""
      :width="dialogSize"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <majorVerification />
    </el-dialog>

    <router-view @click.stop="closeMenu()" />
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import TheNavigationVue from '@/components/navigation/navigation_V2.vue'
import TheFooter from '@/components/Footer_V2.vue'
import { mapActions } from 'vuex'
import m from 'moment';
import majorVerification from '@/components/modules/dialogs/majorVerification.vue'
import WebSocketManager from '@/services/websocket.js';

export default {
  components: {
    TheNavigationVue,
    TheFooter,
    majorVerification,
  },
  data() {
    return {
      menuAlerts: false,
      darkmode: true,
    }
  },
  async mounted() {
    WebSocketManager.connectWebSocket();

    this.getListsActions();
    this.authverificationActions();

    if (localStorage.getItem('loginAt')) {
        if (m(localStorage.getItem('loginAt'), 'YYYY-MM-DD HH:mm:ss').add(12, 'h').format('YYYY-MM-DD HH:mm:ss') < m().format('YYYY-MM-DD HH:mm:ss')) {
          await this.logoutAPI().then(async() => {
            localStorage.removeItem('loginAt');
            localStorage.removeItem('token')
            this.$store.commit('RESET_SEARCH', {
              key: 'searchProduct',
              products: [],
            })
            this.$store.commit('SET_AUTH', false)
            this.$store.commit('EMAIL_IS_CONFIRM', false)
            this.$store.commit('CHANGE_REGISTER_STEP', 1)
            this.$store.commit('CHANGE_2FA_STEP', 0)
            this.$router.push('/login');
          })
        }
    }

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', navigator.language ? navigator.language.slice(0, 2) : 'en');
      this.$i18n.locale = navigator.language ? navigator.language.slice(0, 2) : 'en';
    } else {
      this.$i18n.locale = localStorage.getItem('lang');
    }
  },
  computed: {
    isNoAuth() {
      return !this.$store.state.auth.auth;
    },
    isFirstTime() {
      let ft = false;

      if (!localStorage.getItem('major')) {
        ft = true;
      }

      if (this.path === '/legal/tep' || this.path === '/legal/mangopay') {
        ft = false;
      }

      return ft;
    },
    path() {
      return this.$route.path;
    },
    dialogSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '40%';
    },
  },
  watch: {
    async path() {
      WebSocketManager.sendMessage(this.$route.name);

      if (localStorage.getItem('loginAt')) {
        if (m(localStorage.getItem('loginAt'), 'YYYY-MM-DD HH:mm:ss').add(12, 'h').format('YYYY-MM-DD HH:mm:ss') < m().format('YYYY-MM-DD HH:mm:ss')) {
          await this.logoutAPI().then(async() => {
            localStorage.removeItem('loginAt');
            localStorage.removeItem('token');

            this.$store.commit('RESET_SEARCH', {
              key: 'searchProduct',
              products: [],
            })

            this.$store.commit('SET_AUTH', false);
            this.$store.commit('EMAIL_IS_CONFIRM', false);
            this.$store.commit('CHANGE_REGISTER_STEP', 1);
            this.$store.commit('CHANGE_2FA_STEP', 0);
            this.$router.push('/login');
          })
        }
      }

      if (this.isFirstTime) {
        if (this.path !== '/' && this.path !== '/legal/tep' && this.path !== '/legal/mangopay') {
          this.$router.push('/');
        }
      }
      window.scrollTo(0, 0);
    }
  },
  methods: {
    ...mapActions({
      getListsActions: 'getLists',
      authverificationActions: 'authverification',
      logoutAPI: 'logout'
    }),
    closeMenu() {
      this.menuAlerts = false;
    },
    openMenuAlerts(state) {
      if (state) {
        this.menuAlerts = false;
      } else {
        this.menuAlerts = !this.menuAlerts;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

@font-face {
  font-family: Inter;
  src: url('./assets/font/Inter/Inter-VariableFont_slnt,wght.ttf')
    format('TrueType');
}

@font-face {
  font-family: Hyperwave;
  src: url('./assets/font/Hyperwave Display.otf') format('OpenType');
}

.el-input__wrapper {
  background: #333333!important;
}

.hover {
  background: #7c7c7c !important;
}

.selected {
  color: white !important;
}

.el-select-dropdown__item > span,
.el-input__inner {
  color: #fff !important;
}

.appbody {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
  min-height: 100svh;
}
</style>
