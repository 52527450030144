<template>
  <div class="home" id="home">
    <div class="home-heading">
      <!-- <p class="revolution">{{ $t('home.revolution') }}</p> -->
      <h1>{{ $t('home.title') }}</h1>
    </div>
    <img
      class="home-banner"
      src="@/assets/svg/v2/home/homeBanner.svg"
      alt="Banner image"
    />
    <p class="subtitle">{{ $t('home.subtitle') }}</p>

    <img
      class="home-background"
      src="@/assets/svg/v2/home/background.svg"
      alt="TEP home background"
    />

    <div class="search-bar">
      <search :isHome="true"></search>
    </div>

    <div class="checked-items">
      <div class="secure-transactions">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
        <p>{{ $t('home.secure_transactions') }}</p>
      </div>
      <div class="low-fees">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
        <p>{{ $t('home.fees') }}</p>
      </div>
      <div class="verified-accounts">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
        <p>{{ $t('home.verified_accounts') }}</p>
      </div>
    </div>

    <subscribeNewsletter :row="true" />

    <div class="bloc-create-account">
      <h2>{{ $t('home.title_2') }}</h2>
      <button @click="redirect('/register')">
        <p>{{ $t('home.create_account') }}</p>
        <img src="@/assets/svg/v2/home/right.svg" alt="Create account" />
      </button>
    </div>

    <div class="bloc-create-account-tel">
      <h2>{{ $t('home.title_2') }}</h2>
      <button @click="redirect('/register')">
        <p>{{ $t('home.create_account') }}</p>
        <img src="@/assets/svg/v2/home/right.svg" alt="Create account" />
      </button>
    </div>

    <div class="trading-without-subscriptions">
      <h3>{{ $t('home.without_subscriptions') }}</h3>
    </div>

    <div class="buy-sell">
      <div>
        <h3 class="hidden-desktop">{{ $t('home.buy_and_sell') }}</h3>
      </div>
      <!-- <img src="@/assets/svg/v2/home/infosBuySell.svg" alt="BuySell" /> -->
      <video v-if="!isLoading" id="video-presentation-trading" width="400px" height="280px" controls>
        <source
          :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/presentation_${returnLocale}.mp4`"
          type="video/mp4"
        />
      </video>
      <div>
        <h3 class="hidden-tel">{{ $t('home.buy_and_sell') }}</h3>
        <p>{{ $t('home.buy_and_sell_description') }}</p>
      </div>
    </div>

    <div class="swap">
      <div>
        <h3 class="hidden-tel">{{ $t('home.swap') }}</h3>
        <p>{{ $t('home.swap_description') }}</p>
      </div>
      <img src="@/assets/svg/v2/home/infosSwap.svg" alt="Swap" />
      <div>
        <h3 class="hidden-desktop">{{ $t('home.swap') }}</h3>
      </div>
    </div>

    <div class="blocktrade">
      <div>
        <h3 class="hidden-desktop">{{ $t('home.blocktrade') }}</h3>
      </div>
      <!-- <img src="@/assets/svg/v2/home/infosBlocktrade.svg" alt="Blocktrade" /> -->
      <video v-if="!isLoading" id="video-presentation-blocktrade" width="400px" height="280px" controls>
        <source
          :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/blocktrade_en-GB.mp4`"
        />
      </video>
      <div>
        <h3 class="hidden-tel">{{ $t('home.blocktrade') }}</h3>
        <p>{{ $t('home.blocktrade_description') }}</p>
      </div>
    </div>

    <div class="portfolio-price-history">
      <div class="price-history">
        <div class="elem1">
          <img
            src="@/assets/img/price_history_whmacc1.webp"
            alt="Price history"
          />
        </div>
        <h3 class="elem2">{{ $t('home.price_history') }}</h3>
        <p class="elem3">{{ $t('home.price_history_description') }}</p>
      </div>
      <div class="portfolio">
        <div class="elem1">
          <img src="@/assets/img/portfolio.webp" alt="Portfolio" />
        </div>
        <h3 class="elem2">{{ $t('home.portfolio') }}</h3>
        <p class="elem3">{{ $t('home.portfolio_description') }}</p>
      </div>
    </div>

    <el-divider class="divider-guarantee" />

    <div class="guarantee">
      <h2>{{ $t('home.title_3') }}</h2>

      <div class="guarantee-bloc">
        <div class="first-guarantee-bloc">
          <div class="low-fees">
            <div>
              <img src="@/assets/svg/v2/home/lowFees1.svg" alt="Low fees" />
              <img src="@/assets/svg/v2/home/lowFees2.svg" alt="Low fees" />
            </div>
            <h3>{{ $t('home.low_fees') }}</h3>
            <p>{{ $t('home.low_fees_description') }}</p>
          </div>
          <div class="verified-accounts">
            <div>
              <img
                src="@/assets/svg/v2/home/verifiedAccount.svg"
                alt="Verified account"
              />
            </div>
            <h3>{{ $t('home.verified_accounts') }}</h3>
            <p>{{ $t('home.verified_accounts_description') }}</p>
          </div>
        </div>
        <div class="second-guarantee-bloc">
          <div class="anonymous-transactions">
            <div>
              <img
                src="@/assets/svg/v2/home/anonymousTransactions.svg"
                alt="Anonymous transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.anonymous_transactions') }}</h3>
            <p>{{ $t('home.anonymous_transactions_description') }}</p>
          </div>
          <div class="secure-transactions">
            <div>
              <img
                src="@/assets/svg/v2/home/secureTransactions.svg"
                alt="Secure transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.secure_transactions') }}</h3>
            <p>{{ $t('home.secure_transactions_description') }}</p>
          </div>
        </div>
      </div>
    </div>

    <AccountDetailsVerification :homePage="true" />

    <div class="subscriptions">
      <h2>{{ $t('home.title_4') }}</h2>

      <subscriptions :homePage="true"></subscriptions>
    </div>

    <div class="pre-footer">
      <h2>{{ $t('home.join_community') }}</h2>

      <div class="checked-items">
        <div class="secure-transactions">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
          <p>{{ $t('home.secure_transactions') }}</p>
        </div>
        <div class="low-fees">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
          <p>{{ $t('home.fees') }}</p>
        </div>
        <div class="verified-accounts">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item" />
          <p>{{ $t('home.verified_accounts') }}</p>
        </div>
      </div>

      <button @click="redirect('/register')" class="generic-btn">
        {{ $t('home.create_account') }}
      </button>
    </div>
  </div>
</template>

<script>
import search from '@/components/modules/search_V2.vue'
import subscriptions from '../components/subscriptions.vue'
import AccountDetailsVerification from '@/components/accountCertifInformations.vue'
import subscribeNewsletter from '@/components/modules/newsletter/subscribeNewsletter.vue'

export default {
  name: 'Home',
  components: {
    search,
    subscriptions,
    AccountDetailsVerification,
    subscribeNewsletter,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    this.$store.commit('SET_POSITION', 'home')

    const video = document.getElementById('video-presentation-trading')
    video.addEventListener('loadedmetadata', function () {
      video.currentTime = 1
    })

    const videoBlocktrade = document.getElementById(
      'video-presentation-blocktrade'
    )
    videoBlocktrade.addEventListener('loadedmetadata', function () {
      videoBlocktrade.currentTime = 1
    })
  },
  watch: {
    returnLocale() {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    isLoading() {
      if (!this.isLoading) {
        this.$nextTick(() => {
          const video = document.getElementById('video-presentation-trading')
          video.addEventListener('loadedmetadata', function () {
            video.currentTime = 1
          })

          const videoBlocktrade = document.getElementById(
            'video-presentation-blocktrade'
          )
          videoBlocktrade.addEventListener('loadedmetadata', function () {
            videoBlocktrade.currentTime = 1
          })
        })
      }
    },
  },
  computed: {
    returnLocale() {
      // return this.$i18n.locale;
      return 'en-GB'
    },
  },
  methods: {
    redirect(path) {
      this.$router.push(path)
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.home,
.home-heading,
.buy-sell,
.swap,
.blocktrade,
.portfolio-price-history,
.checked-items,
.checked-items div,
.guarantee {
  display: flex;
}

.home {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .home-heading {
    flex-direction: column-reverse;
    padding-top: 10px;

    .revolution {
      background: linear-gradient(
        270deg,
        #add7f6 12.67%,
        #3b28cc 34.96%,
        #2667ff 55.5%,
        #add7f6 76.91%,
        #3f8efc 96.58%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border-radius: 46px;
      border: 1px solid #87bfff;
      margin: 10px auto 0px;

      width: 170px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 6px 0;
    }

    h1 {
      font-family: Hyperwave;
      text-align: center;
      margin: 15px auto 20px;
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 40px;
    }
  }

  .home-banner {
    margin: 30px auto;
    object-fit: cover;
  }

  .subtitle {
    text-align: center;
    max-width: 635px;
    margin: auto;
    margin-bottom: 25px;
  }

  .home-background {
    position: absolute;
    top: -110px;
    z-index: -1;
    width: 1200px;
    height: 1100px;
  }

  .checked-items {
    margin-top: 27px;
    margin-bottom: 30px;

    div {
      margin-left: 33px;

      img {
        margin-right: 5px;
      }

      p {
        color: #fff;
      }
    }
  }

  .bloc-create-account {
    background: no-repeat url('../assets/img/background_2.webp');
    min-height: 220px;
    margin-bottom: 30px;
    max-width: 1300px;
    border-radius: 12px;
    width: 100%;

    h2 {
      font-family: Hyperwave;
      color: white;
      font-size: 30px;
      font-weight: 400;
      max-width: 745px;
      margin-bottom: 25px;
      padding-top: 55px;
      padding-left: 43px;
    }

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent;
      font-size: 15px;
      color: white;
      border: 0;
      padding-left: 43px;

      img {
        margin-left: 5px;
      }
    }
  }

  .bloc-create-account-tel {
    display: none;
  }

  .divider-guarantee {
    border-color: $border;
    margin: 0;
    width: 92%;
  }

  .guarantee {
    width: 100%;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: Hyperwave;
      color: white;
      font-size: 40px;
      font-weight: 400;
      margin-top: 47px;
      margin-bottom: 54px;
      text-align: center;
    }

    .guarantee-bloc {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 50px;
      gap: 23px;
      margin-left: 23px;
      margin-right: 23px;

      .first-guarantee-bloc,
      .second-guarantee-bloc {
        display: flex;
        flex-wrap: wrap;
        gap: 23px;
        justify-content: center;
      }

      .secure-transactions,
      .low-fees,
      .anonymous-transactions,
      .verified-accounts {
        border-radius: 20px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        border: 1px solid $border;
        background: #333;
        width: 293px;
        padding-left: 23px;
        padding-right: 10px;
        height: 300px;

        div {
          margin-top: 47px;
          margin-bottom: 23px;
        }

        h3 {
          color: white;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          color: $subtitle;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .low-fees {
        div {
          display: flex;
          justify-content: flex-start;

          img {
            margin-right: 5px;
          }
        }
      }

      .secure-transactions {
        background: linear-gradient(
          180deg,
          rgba(168, 85, 247, 0.16) 0%,
          rgba(168, 85, 247, 0) 100%
        );
      }

      .low-fees {
        background: linear-gradient(
          180deg,
          rgba(132, 204, 22, 0.08) 0%,
          rgba(132, 204, 22, 0) 100%
        );
      }

      .anonymous-transactions {
        background: linear-gradient(
          180deg,
          rgba(245, 158, 11, 0.08) 0%,
          rgba(245, 158, 11, 0) 100%
        );
      }

      .verified-accounts {
        background: linear-gradient(
          180deg,
          rgba(38, 103, 255, 0.08) 0%,
          rgba(38, 103, 255, 0) 100%
        );
      }
    }
  }

  .subscriptions {
    h2 {
      color: white;
      font-family: Hyperwave;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.trading-without-subscriptions {
  margin-bottom: 30px;
  margin-top: 10px;

  h3 {
    font-family: Hyperwave;
    font-size: 34px;
    font-weight: 700;
    color: white;
  }
}

.buy-sell,
.swap,
.blocktrade {
  margin-bottom: 30px;

  .hidden-desktop {
    display: none;
  }

  video {
    max-width: 450px;
  }

  video::-internal-media-controls-download-button {
    display: none;
  }
  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }

  div {
    max-width: 562px;
    margin-left: 150px;

    h3 {
      font-family: Hyperwave;
      font-size: 34px;
      font-weight: 700;
      color: white;
      margin-bottom: 25px;
    }

    p {
      color: $subtitle;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.swap {
  div {
    margin-right: 150px;
  }
}

.portfolio-price-history {
  margin: 0 70px;
  margin-bottom: 70px;
  max-width: 1400px;

  .portfolio,
  .price-history {
    width: 50%;

    div {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: flex-start;
      height: 270px;
      padding: 5px;
      border-radius: 20px;
      border: 1px solid $border;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      margin-bottom: 24px;
      max-width: 650px;

      img {
        width: 95%;
        height: 100%;
        max-width: 540px;
      }
    }

    h3 {
      color: white;
      font-family: Hyperwave;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 13px;
    }

    p {
      color: $subtitle;
    }
  }

  .price-history {
    margin-right: 25px;
  }
}

.pre-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 433px;
  background: no-repeat url('../assets/img/prefooter-background.webp');
  background-size: cover;

  h2 {
    font-family: Hyperwave;
    text-align: center;
    max-width: 622px;
    color: white;
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
  }

  .checked-items {
    margin-top: 18px;
    margin-bottom: 34px;
  }

  button {
    width: 230px;
  }
}

@media screen and (max-width: 1300px) {

  .buy-sell, .swap, .blocktrade {
    width: 100%;

    div {
      margin: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    .home-heading {
      flex-direction: column;
      padding: 0 15px;

      .revolution {
        margin: 30px auto;
      }
      h1 {
        font-size: 30px;
        margin-top: 15px;
      }
    }
    .home-banner {
      width: 90%;
      margin: 0 auto 20px;
    }

    .subtitle {
      max-width: 350px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .checked-items {
    flex-direction: column;
    margin-bottom: 33px !important;

    div {
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
  }

  .bloc-create-account {
    display: none;
  }

  .bloc-create-account-tel {
    display: block !important;
    background: no-repeat
      url('../assets/svg/v2/home/backgroundCreateAccountTel.svg');
    background-size: cover;
    margin-bottom: 40px;
    width: 100%;

    h2 {
      font-family: Hyperwave;
      color: white;
      font-size: 26px;
      font-weight: 700;
      max-width: 302px;
      margin-bottom: 25px;
      padding-top: 47px;
      padding-left: 40px;
    }

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent;
      font-size: 15px;
      color: white;
      border: 0;
      padding-left: 40px;
      margin-bottom: 70px;

      img {
        margin-left: 5px;
      }
    }
  }

  .trading-without-subscriptions {
    h3 {
      text-align: center;
    }
  }

  .buy-sell,
  .swap,
  .blocktrade {
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;

    .hidden-desktop {
      display: flex;
      position: absolute;
      left: 21px;
    }

    .hidden-tel {
      display: none;
    }

    video {
      padding-top: 25px;
      width: 320px;
    }

    div {
      margin: 30px 21px;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 15px;
      }
    }

    img {
      max-width: 287px;
    }
  }

  .swap {
    flex-direction: column-reverse;

    img {
      padding: 25px 0 0;
    }
  }

  .portfolio-price-history {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-top: 20px;

    .portfolio,
    .price-history {
      display: grid;
      grid-template-areas: 'elem2' 'elem1' 'elem3';
      width: 90%;
      margin: auto;
      margin-bottom: 51px;

      .elem1 {
        grid-area: elem1;
        margin: 10px auto 20px;
      }

      div {
        width: 100%;
        max-height: 175px;
        max-width: 350px;
        margin-bottom: 20px;

        img {
          width: 99%;
        }
      }
    }
  }

  .guarantee {
    h2 {
      font-size: 30px !important;
      font-weight: 700 !important;
    }

    .guarantee-bloc {
      overflow: hidden;
      flex-direction: column;
      margin-bottom: 20px !important;

      .secure-transactions,
      .low-fees,
      .anonymous-transactions,
      .verified-accounts {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
    }
  }

  .subscriptions {
    h2 {
      font-size: 30px !important;
      font-weight: 700 !important;
      max-width: 286px;
      margin: auto;
    }
  }

  .pre-footer {
    h2 {
      max-width: 348px;
      margin-top: 30px;
    }

    button {
      margin-bottom: 70px;
    }
  }

  .home-background {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .home {
    .home-heading {
      .revolution {
        margin: 25px auto;
      }
    }

    .subtitle {
      margin: 0 10px 30px;
    }
  }
}
</style>
