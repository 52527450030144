import { ElMessage } from 'element-plus';
import { api } from '@/services/interceptors';
import i18n from '../i18n';
import router from '../router/index';

export default {
  state: {
    user: null,
    dialog_banking_informations: false,
    subscriptions: [],
    subscriptions_informations: null,
    invoices: [],
    alerts: []
  },
  getters: {
    getUserDetails: (currentState) => (currentState.user ? currentState.user : null),
    getAlerts(state) {
      return state.alerts;
    },
    getSubscriptions(state) {
      return state.subscriptions;
    },
    getSubscriptionsInformations(state) {
      return state.subscriptions_informations;
    },
    getInvoices(state) {
      return state.invoices;
    },
  },
  actions: {
    getAccountDetails({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        api.get('api/auth/client')
          .then((res) => {
            dispatch('getAlerts');
            commit('SET_USER_DETAILS', res.data.user_details);
            resolve(res.data.user_details);
          })
          .catch((error) => {            
            if (error.response.status === 401) {
              router.push('/login');
            } else {
              reject();
            }
          });
      });
    },
    getSubscriptions({ commit }) {
      api.get('api/auth/client/subscriptions')
        .then((res) => {
          commit('SET_SUBSCRIPTIONS', res.data.subscriptions);
          commit('SET_SUBSCRIPTIONS_INFOS', res.data.infos);
        });
    },
    updateSubscriptions({ commit }, payload) {
      api.put('api/auth/client/subscriptions', payload)
        .then((res) => {
          commit('SET_SUBSCRIPTIONS', res.data.subscriptions);
        });
    },
    getEmailPhoneCode({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.post('api/generation/code', payload);
    },
    confirmNewValue({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.put('api/auth/account/check/contacts', payload)
          .then(() => {
            dispatch('getAccountDetails');
            resolve();
          }).catch((err) => {
            console.log(err);
            reject();
          })
      })
    },
    changeDeliveryAddress({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.put('api/auth/client/delivery-address', payload)
          .then(() => {
            dispatch('getAccountDetails');
            resolve();
          }).catch(() => {
            reject();
          })
      })
    },
    changeBankingInformations({ commit, dispatch }, payload) {
      api.put('api/auth/client/banking-informations', payload)
        .then(() => {
          dispatch('getAccountDetails');
          commit('CHANGE_DIALOG_BANKING_INFORMATIONS', false);
        });
    },
    changeSellingBuyingPreferences({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', payload);
      api.put('api/auth/client/purchase-sell', payload)
        .then(() => {
          ElMessage({
            message: i18n.global.t('settings.buySellPreferences.save_success'),
            type: 'success',
            grouping: true
          });
          dispatch('getAccountDetails');
        });
    },
    async changePreferences({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', payload);
      api.put('api/preferences', payload)
        .then(() => {
          ElMessage({
            message: i18n.global.t('settings.preferences_save'),
            type: 'success',
            grouping: true
          });
          dispatch('getAccountDetails');
        });
    },
    getAlerts({ commit }) {
      return new Promise((resolve) => {
      api.get('api/alerts')
        .then((res) => {
          commit('ALERTS', res.data.alerts);
          resolve(res.data.alerts);
        });
      });
    },
    getInvoicesHistory({ commit }) {
      api.get('api/auth/client/history/invoices')
        .then((res) => {
          commit('INVOICES', res.data.invoices);
        });
    },
    downloadInvoice({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.get(`api/auth/client/history/invoice/download/${payload}`, { responseType: 'arraybuffer', responseEncoding: 'binary', headers: { 'Content-Type': 'application/pdf' } })
        .then((res) => {
          let blob = new Blob([res.data], { type: 'application/pdf' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
    updateLang({ commit }, payload) {
      commit('STORE_NOTHING', payload);
      api.post('api/auth/client/lang', payload);
    },
    async card({ commit, dispatch }, payload) {
      commit('STORE_NOTHING', payload);
      api.put('api/auth/client/payment-method', payload)
        .then(() => {
          dispatch('getAccountDetails');
        });
    },
  },
  mutations: {
    SET_USER_DETAILS(currentState, user) {
      currentState.user = user;
    },
    CHANGE_DIALOG_BANKING_INFORMATIONS(currentState, state) {
      currentState.dialog_banking_informations = state;
    },
    ALERTS(currentState, alerts) {
      currentState.alerts = alerts;
    },
    INVOICES(currentState, invoices) {
      currentState.invoices = invoices;
    },
    SET_SUBSCRIPTIONS(currentState, subscriptions) {
      currentState.subscriptions = subscriptions;
    },
    SET_SUBSCRIPTIONS_INFOS(currentState, infos) {
      currentState.subscriptions_informations = infos;
    },
    STORE_NOTHING() { }
  }
};