<template>
  <div class="major-dialog">
    <div class="major-dialog-content">
      <div class="major-dialog-content-body">
        <h1>{{ $t('major.title') }}</h1>
        <p>{{ $t('major.subtitle') }}</p>

        <div class="major-dialog-actions">
          <button @click="changeMajorStatus()" class="generic-btn">
            {{ $t('major.ok') }}
          </button>
          <button class="generic-btn nok">
            <a href="https://google.fr">{{ $t('major.nok') }}</a>
          </button>
        </div>

        <div
          class="major-dialog-conditions"
          @click="$router.push('/legal/tep')"
        >
          <p>
            {{ $t('major.conditions_1') }}
            <a @click="$router.push('/legal/tep')">{{
              $t('major.conditions_2')
            }}</a>
            {{ $t('major.conditions_3') }}
            <a @click="$router.push('/legal/tep')">{{
              $t('major.conditions_4')
            }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    path() {
      return this.$route.path
    },
  },
  watch: {
    path() {
      if (this.path === '/legal/tep' || this.path === '/legal/mangopay') {
        let home = document.getElementById('home')
        home.style.filter = 'none'
      }
    },
  },
  methods: {
    changeMajorStatus() {
      localStorage.setItem('major', true)
      window.location.reload()
    },
  },
  mounted() {
    if (!localStorage.getItem('major')) {
      setTimeout(() => {
        let home = document.getElementById('home')
        home.style.filter = 'blur(10px)'

        let nav = document.getElementById('nav')
        nav.style.filter = 'blur(10px)'

        let footer = document.getElementById('footer')
        footer.style.filter = 'blur(10px)'
      }, 500)
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.major-dialog {

  .major-dialog-content {
    .major-dialog-content-body {
      width: 100%;

      h1 {
        color: white;
        max-width: 506px;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
        font-size: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        margin: auto;
        text-align: center;
      }

      .major-dialog-actions {
        display: flex;
        justify-content: space-between;
        margin: 0 23px;
        margin-top: 15px;

        .generic-btn {
          width: 271px;
          margin: 8px;
        }

        .nok {
          background: #555555;

          a {
            color: white;
          }
        }
      }

      .major-dialog-conditions {
        display: flex;
        height: 55px;
        margin-top: 20px;

        p {
          font-size: 14px;
          font-weight: 500;
          max-width: 450px;

          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .major-dialog-actions {
    flex-wrap: wrap;
    justify-content: center !important;

    button {
      margin-bottom: 10px;
    }
  }
}
</style>
