import { api } from '@/services/interceptors';
import { ElMessage, ElLoading } from 'element-plus';
import router from "../router/index";
import i18n from '../i18n';

export default {
  state: {
    product: [],
    cart: [],
    create_order_dialog: false,
    pre_trade_dialog: false,
    orders: [],
    order: [],
    locations: [],
    locationSelected: null,
    max_check_product: true,

    priceHistoryData: [],
    priceHistoryInfos: null,

    priceHistoryCompositeData: [],
    priceHistoryCompositeInfos: null,

    priceHistoryDataTags: [],
    priceHistoryInfosTags: [],

    currency: 'EUR',
    tags: [],
    compositePriceHistoryTags: [],
    compositeAvailableSizes: [],

    abortControllerComposite: null,
    abortControllerPriceHistory: null,
  },
  getters: {
    getProduct (state) {
      return state.product;
    },
    getCart (state) {
      return state.cart;
    },
    getPriceHistory (state) {
      return state.priceHistoryData;
    },
    getPriceHistoryInfos (state) {
      return state.priceHistoryInfos;
    },
    getPriceCompositeHistory (state) {
      return state.priceHistoryCompositeData;
    },
    getAvailablesSizes(state) {
      return state.compositeAvailableSizes;
    },
    getPriceHistoryCompositeInfos (state) {
      return state.priceHistoryCompositeInfos;
    },
    getPriceHistoryTags (state) {
      return state.priceHistoryDataTags;
    },
    getCompositePriceHistoryTags (state) {
      return state.compositePriceHistoryTags;
    },
    getPriceHistoryInfosTags (state) {
      return state.priceHistoryInfosTags;
    },
    getCurrency (state) {
      return state.currency;
    },
    getTags (state) {
      return state.tags;
    },
    getMaxCheckProduct (state) {
      return state.max_check_product;
    },
    getOrders(state) {
      return state.orders;
    },
    getOrder(state) {
      return state.order;
    },
    getLocations(state) {
      return state.locations;
    },
    getLocationSelected(state) {
      return state.locationSelected;
    },
    getCreateOrderDialog(state) {
      return state.create_order_dialog;
    },
    getPreTradeDialog(state) {
      return state.pre_trade_dialog;
    },
  },
  actions: {
    product({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        api.get('api/auth/product/' + payload.isbn)
          .then((res) => {
            commit('PRODUCT', res.data.product);
            resolve();
          }).catch(() => {
            reject();
          })
      })
    },
    errorMissingBottle({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
        api.post('api/global/report/bottle', payload)
          .then(() => {
            ElMessage({
              message: i18n.global.t('product_page.success_send_error_missing'),
              type: 'success',
              grouping: true,
              duration: 6000
            });
            resolve();
          })
          .catch((e) => {
            reject();
            e.response.request.status === 401 ? dispatch('redirect') : null;
          });
      });
    },
    choiceCurrency({ commit }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
        api.post('api/auth/product/currency', payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      })
    },
    priceHistoryAuth({ commit, dispatch }, payload) {
      dispatch('cancelHistory');
      const controller = new AbortController();
      commit('ABORT_HISTORY', controller);

      if (payload.isbn) {
        api.get('api/auth/product/price-history/' + payload.isbn + '/' + payload.currency + '/' + payload.with_fees, {
          signal: controller.signal
        })
        .then((res) => {
            if (payload.is_tag) {
              commit('PRICE_HISTORY_TAGS', {isbn: payload.isbn, data: res.data.price_history});
              commit('PRICE_HISTORY_INFOS_TAGS', { isbn: payload.isbn, data: res.data.infos });
              commit('TAGS', payload);
            } else {
              commit('PRICE_HISTORY', res.data.price_history);
              commit('PRICE_HISTORY_INFOS', res.data.infos);
            }
          }).catch(() => {})
      }
    },
    compositePriceHistory({ commit, dispatch }, payload) {
      dispatch('cancelComposite');
      const controller = new AbortController();
      commit('ABORT_COMPOSITE', controller);
      
      api.get('api/auth/product/price-history/' + payload.ticker_composite + '/' + payload.currency, {
        signal: controller.signal
      })
      .then((res) => {
        commit('PRICE_HISTORY_COMPOSITE', res.data.price_history);
        commit('PRICE_HISTORY_COMPOSITE_INFOS', res.data.infos);
        commit('AVAILABLE_SIZES', res.data.sizes);
      });
    },
    cancelComposite({ state }) {
      if (state.abortControllerComposite) {
        state.abortControllerComposite.abort();
      }
    },
    cancelHistory({ state }) {
      if (state.abortControllerPriceHistory) {
        state.abortControllerPriceHistory.abort();
      }
    },
    getOrders({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', payload);
        if (payload) {
          api.get('api/auth/orders/' + payload).then((res) => {
            commit('ORDERS', res.data.orders);
            resolve(res.data.orders);
          }).catch(() => {
            reject();
          })
        }
      })
    },
    getOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        let shipmentType = 0;

        if (payload.shipment === 1) {
          shipmentType = 1;
        }

        api.get('api/auth/order/' + payload.uuid + '/' + shipmentType)
          .then((res) => {
            if (res.data.order) {
              dispatch('getAlerts');
              resolve(res.data.order);
              commit('ORDER', res.data.order);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    location({ commit }) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });

      return new Promise((resolve, reject) => {
        api.get('api/auth/order/locator/address').then((res) => {
            commit('SET_LOCATIONS', res.data.locations);
            resolve();
          })
          .catch(() => {
            reject();
          }).finally(() => {
            loading.close();
          })
      });
    },
    agreeOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/agree', payload).then(() => {
          resolve();
        }).catch((err) => {
          reject(err);
        })
      })
    },
    createOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api
          .post('api/auth/order', payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve();
            }
          })
          .catch((e) => {
            reject(e);
            if (e.response.data?.is_pretrade) {
              ElMessage({
                message: i18n.global.t('product_page.orders.order_create_block_by_match'),
                type: 'error',
                grouping: true,
                duration: 6000,
              });

              router.push('/auth/notifications');
            }
          });
      });
    },
    sendBidProposition({ commit }, payload) {
      commit('STORE_NOTHING');
      api.post('api/auth/orders/trade-confirmation/bid/proposition', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    createSellOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api
          .post('api/auth/order/sell', payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createSwapOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/swap', payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    updateSwapOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/auth/order/swap', payload)
        .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    createBuyOrder({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/buy', payload, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then((res) => {
            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateBuyOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/auth/order/buy', payload)
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    updateOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/auth/order/sell', payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(() => {
              resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    createBlockTrade({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/blocktrade', payload, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then((res) => {
            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createOrderV2({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order', payload, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then((res) => {

            if (res.data.isbn) {
              dispatch('getOrders', res.data.isbn);
              resolve(res.data);
            }

          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateOrderV2({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.put('api/auth/order', payload, { headers: { 'Content-Type': 'multipart/form-data' }})
          .then((response) => {
              resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    confirmDelivery({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/shipment/payout', payload)
          .then(() => {
              resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    disputeDelivery({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/order/shipment/payout/dispute', payload)
          .then(() => {
              resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    refuseMatch({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.post('api/auth/orders/trade-confirmation/0', payload)
          .then(() => {
            dispatch('getAlerts');
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    PRODUCT(currentState, product) {
      currentState.product = [];
      currentState.product = product;
    },
    PRICE_HISTORY(currentState, history) {
      currentState.priceHistoryData = history;
    },
    PRICE_HISTORY_INFOS(currentState, infos) {
      currentState.priceHistoryInfos = infos;
    },
    PRICE_HISTORY_COMPOSITE(currentState, history) {
      currentState.priceHistoryCompositeData = [];
      currentState.priceHistoryCompositeData = history;
    },
    PRICE_HISTORY_COMPOSITE_INFOS(currentState, infos) {
      currentState.priceHistoryCompositeInfos = infos;
    },
    AVAILABLE_SIZES(currentState, sizes) {
      currentState.compositeAvailableSizes = sizes;
    },
    PRICE_HISTORY_TAGS(currentState, { isbn, data }) {
      currentState.priceHistoryDataTags.push({isbn, data});
    },       
    REMOVE_PRICE_HISTORY_TAGS(currentState, indexTag) {
      currentState.priceHistoryDataTags.slice(indexTag, 1);
    },
    COMPOSITE_PRICE_HISTORY_TAGS(currentState, {isbn, data}) {
      currentState.compositePriceHistoryTags.push({isbn, data});
    },
    PRICE_HISTORY_INFOS_TAGS(currentState,  {isbn, data}) {
      currentState.priceHistoryInfosTags.push({isbn, data});
    },       
    REMOVE_PRICE_HISTORY_INFOS_TAGS(currentState, indexTag) {
      currentState.priceHistoryInfosTags.slice(indexTag, 1);
    },
    CURRENCY(currentState, currency) {
      currentState.currency = currency;
    },
    IS_MAX_CHECK_PRODUCT(currentState, state) {
      currentState.max_check_product = state;
    },
    STORE_NOTHING() { },
    TAGS(currentState, tag) {
      currentState.tags.push(tag);
    },
    REMOVE_TAG (currentState, tag) {
      if(currentState.tags.includes(tag)){        
        currentState.tags.splice(currentState.tags.indexOf(tag), 1);
      }
    },
    ABORT_COMPOSITE(state, controller) {
      state.abortControllerComposite = controller;
    },
    ABORT_HISTORY(state, controller) {
      state.abortControllerPriceHistory = controller;
    },
    RESET_TAGS(currentState) {
      currentState.tags = [];
    },
    SET_LOCATIONS(currentState, locations) {
      currentState.locations = locations;
    },
    SET_LOCATION_SELECTED(currentState, location) {
      currentState.locationSelected = location;
    },
    ORDERS(currentState, product) {
      currentState.orders = [];
      currentState.orders = product;
    },
    ORDER(currentState, product) {
      currentState.order = [];
      currentState.order = product;
    },
    CREATE_ORDER_DIALOG(currentState, state) {
      currentState.create_order_dialog = state;
    },
    PRE_TRADE_DIALOG(currentState, state) {
      currentState.pre_trade_dialog = state;
    },
    ADD_PRODUCT_CART(currentState, product) {
      currentState.cart.push(product);
    },
    REMOVE_PRODUCT_CART(currentState, indexProduct) {
      currentState.cart.splice(indexProduct, 1);      
    },
  }
};