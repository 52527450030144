import { api } from "@/services/interceptors";
import { ElMessage } from 'element-plus';
import i18n from '../i18n';

export default {
  state: {
    wallet: null,
    transactions: [],
  },
  getters: {
    getWallet(state) {
      return state.wallet;
    },
    getWalletTransactions(state) {
        return state.transactions;
      }
  },
  actions: {
    myWallet({ commit }) {
      api.get('api/auth/wallet').then((res) => {
        commit('SET_MY_WALLET', res.data.wallet);
        commit('SET_MY_TRANSACTIONS', res.data.transactions);
      });
    },
    walletPayout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING', null);
        api.get('api/auth/wallet/payout').then(async() => {
          resolve();

          ElMessage({
            message: i18n.global.t('wallet.success_payout_confirm'),
            type: 'success',
            grouping: true,
            duration: 6000,
          });
        }).catch(() => {

          ElMessage({
            message: i18n.global.t('wallet.failed_payout_confirm'),
            type: 'error',
            grouping: true,
            duration: 6000,
          });

          reject();
        })
      })
    },
  },
  mutations: {
    SET_MY_WALLET(currentState, wallet) {
      currentState.wallet = wallet;
    },
    SET_MY_TRANSACTIONS(currentState, transactions) {
        currentState.transactions = transactions;
      },
    STORE_NOTHING() {},
  },
};
