import axios from 'axios';
import { api } from '@/services/interceptors';

export default {
  state: {
    search: {
      'portfolio': null,
      'searchProduct': null,
      'productPage': null,
      'orderSearch': null
    },
    type: {
      'portfolio': 14,
      'searchProduct': 14,
      'productPage': 14,
    },
    products: {
      'portfolio': [],
      'searchProduct': [],
      'productPage': [],
      'bottlerPage': [],
      'orderSearch': []
    },
    products_total: {
      'portfolio': null,
      'searchProduct': null,
      'productPage': null,
      'bottlerPage': null,
      'orderSearch': null
    },
    suggests_products_swap: {
      'portfolio': [],
      'searchProduct': [],
      'productPage': [],
      'bottlerPage': [],
      'orderSearch': []
    },
    searchPending: {
      'portfolio': false,
      'searchProduct': false,
      'productPage': false,
      'bottlerPage': false,
      'orderSearch': null
    },
    searchHistory: [],
    abortSearch: null,
    filters: null,
    filtersHistory: null,

    filtersBottlerPage: null,
    filtersHistoryBottlerPage: null,
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getSearch(state) {
      return state.search;
    },
    getSearchType(state) {
      return state.type;
    },
    getProductsTotal(state) {
      return state.products_total;
    },
    getSearchPending(state) {
      return state.searchPending;
    },
    getSuggestsProducts(state) {
      return state.suggests_products_swap;
    },
    getSearchHistory(state) {
      return state.searchHistory;
    },
    getFilters(state) {
      return state.filters;
    },
    getFiltersHistory(state) {
      return state.filtersHistory;
    },
    getFiltersBottlerPage(state) {
      return state.filtersBottlerPage;
    },
    getFiltersHistoryBottlerPage(state) {
      return state.filtersHistoryBottlerPage;
    }
  },
  actions: {
    autocompleteSearchAuth({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
      dispatch('cancelSearch');
      const controller = new AbortController();
      commit('ABORT_SEARCH', controller);
      commit('SEARCH_PENDING', { key: payload.type, pending: false });
      if (payload.bottler) {
        commit('SET_FILTERS_HISTORY_BOTTLER', payload.filters);
      } else {
        commit('SET_FILTERS_HISTORY', payload.filters);
      }


        api.post('api/auth/product/search', payload, { signal: controller.signal })
          .then((res) => {
            commit('AUTOCOMPLETE_PRODUCTS', { products: res.data.data, key: payload.type });
            commit('SEARCH_PENDING', { key: payload.type, pending: false });
            resolve()
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              resolve()
            } else {              
              commit('SEARCH_PENDING', { key: payload.type, pending: false });
              reject()
            }
          });
      })
    },
    autocompleteSearchSwap({ commit }, payload) {
      commit('SEARCH_PENDING', { key: payload.type, pending: true });
      api.post('api/product/search', payload)
        .then((res) => {
          commit('SUGGESTS_PRODUCTS_SWAP', { suggestsProducts: res.data.data.products, key: payload.type });
          commit('SEARCH_PENDING', { key: payload.type, pending: false });
        })
        .catch(() => {
          commit('SEARCH_PENDING', { key: payload.type, pending: false });
        });
    },
    cancelSearch({ state }) {
      if (state.abortSearch) {
        state.abortSearch.abort();
      }
    },
    searchHistory ({ commit }) {
      commit('STORE_NOTHING');
      api.get('api/auth/product/search/history').then((res) => {
        commit('SEARCH_HISTORY', res.data.search_history);
      })
    },
    addSearchHistory({ commit, state }, payload) {
      let history = state.searchHistory
      const index = history.findIndex(objet => objet.search === payload.search)
      
      if (index !== -1) {
        history.splice(index, 1);
      }
      
      history.push(payload)

      if (history.length > 5) {
        history.shift()
      }

      return new Promise((resolve, reject) => {
        api.post('api/auth/product/search/history', { search_history: history }).then((res) => {
          commit('SEARCH_HISTORY', res.data.search_history)
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    delSearchHistory({ commit, state }, index) {
      let history = state.searchHistory
      history.splice(index, 1);

      return new Promise((resolve, reject) => {
        api.post('api/auth/product/search/history', { search_history: history }).then((res) => {
          commit('SEARCH_HISTORY', res.data.search_history)
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    delAllSearchHistory({ commit }) {
      return new Promise((resolve, reject) => {
        api.post('api/auth/product/search/history', { search_history: [] }).then((res) => {
          commit('SEARCH_HISTORY', res.data.search_history)
          resolve();
        }).catch(() => {
          reject();
        })
      })
    },
    filtersList({ commit }) {
      return new Promise((resolve, reject) => {
        commit('STORE_NOTHING');
        api.get('api/auth/product/search/filters')
          .then((res) => {
            const filters = {
              country : res.data.filters.country
            }
            commit('SET_FILTERS', filters)
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mutations: {
    AUTOCOMPLETE_PRODUCTS(currentState, params) {
      const { products, key } = params;
      if (products.length > 0) {
        currentState.products[key] = [];
        currentState.products[key] = products.products;
        currentState.products_total[key] = products.total;
      } else {
        currentState.products[key] = [];
        currentState.products_total[key] = 0;
      }
    },
    RESET_SEARCH(currentState, params) {
      const { key } = params;
      currentState.products[key] = [];
      currentState.products_total[key] = null;
    },
    SUGGESTS_PRODUCTS_SWAP(currentState, params) {
      const { suggestsProducts, key } = params;
      currentState.suggests_products_swap[key] = [];
      currentState.suggests_products_swap[key] = suggestsProducts;
    },
    SEARCH(currentState, params) {
      const { value, key } = params;
      currentState.search[key] = value;
    },
    TYPE(currentState, params) {
      const { type, key } = params;
      currentState.type[key] = type;
    },
    SEARCH_PENDING(currentState, params) {
      const { pending, key } = params;
      currentState.searchPending[key] = pending;
    },
    ABORT_SEARCH(state, controller) {
      state.abortSearch = controller;
    },
    SEARCH_HISTORY(currentState, params) {
      currentState.searchHistory = [];
      currentState.searchHistory = params;
    },
    SET_FILTERS(currentState, filters) {
      currentState.filters = filters;
    },
    SET_FILTERS_HISTORY(currentState, filters) {
      currentState.filtersHistory = filters;
    },
    SET_FILTERS_HISTORY_BOTTLER(currentState, filters) {
      currentState.filtersHistoryBottlerPage = filters;
    },
    STORE_NOTHING() { }
  }
};