<template>
  <div class="nav-top">
    <div class="bloc-logo-navigation">
      <div @click.stop="$router.push('/')" class="logo">
        <img
          width="130"
          src="@/assets/img/tep.webp"
          alt="The exchange platform - Logo"
        />
      </div>

      <div class="navigation-auth">
        <ul>

          <li
            @click.stop="redirect('/search-products')"
            :style="routerName('searchProducts') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('searchProducts')"
              src="@/assets/svg/v2/trading.svg"
              :alt="$t('commons.trading') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('searchProducts')"
              src="@/assets/svg/v2/tradingActive.svg"
              :alt="$t('commons.trading') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.trading') }}</p>
            <div
              :class="{ 'active-trading': routerName('searchProducts') }"
            ></div>
          </li>

          <li
            @click.stop="redirect('/auth/portfolio-management')"
            :style="routerName('portfolio') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('portfolio')"
              src="@/assets/svg/v2/portfolio.svg"
              :alt="$t('commons.portfolio') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('portfolio')"
              src="@/assets/svg/v2/portfolioActive.svg"
              :alt="$t('commons.portfolio') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.portfolio') }}</p>
            <div :class="{ 'active-portfolio': routerName('portfolio') }"></div>
          </li>
        
          <li
            v-if="!isNoAuth"
            @click.stop="redirect('/auth/matchs')"
            :style="routerName('matchs') ? 'color: white;' : null"
            class="li-match"
          >
            <img
              v-if="!isNoAuth && !routerName('matchs')"
              src="@/assets/svg/v2/matchs/matchs.svg"
              :alt="$t('commons.matchs') + 'icon menu navigation'"
            />
            <img
              v-if="!isNoAuth && routerName('matchs')"
              src="@/assets/svg/v2/matchs/matchsActive.svg"
              :alt="$t('commons.matchs') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.matchs') }}</p>

            <div class="nav-alert-length">
              <p>{{ alerts.length }}</p>
            </div>
            <div
              v-if="alerts.length === 0"
              :class="{ 'active-matchs': routerName('matchs') }"
            ></div>
            <div
              v-else
              :class="{ 'active-matchs-alerts': routerName('matchs') }"
            ></div>
          </li>

          <li
            @click.stop="redirect('/newsfeed')"
            :style="routerName('newsfeed') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('newsfeed')"
              src="@/assets/svg/v2/fullCoverage.svg"
              :alt="$t('commons.newsfeed') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('newsfeed')"
              src="@/assets/svg/v2/fullCoverageActive.svg"
              :alt="$t('commons.newsfeed') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.newsfeed') }}</p>
            <div :class="{ 'active-newsfeed': routerName('newsfeed') }"></div>
          </li>

          <li
            @click.stop="redirect('/blog')"
            :style="routerName('blog') ? 'color: white;' : null"
          >
            <img
              v-if="!routerName('blog')"
              src="@/assets/svg/v2/articles.svg"
              :alt="$t('commons.blog') + 'icon menu navigation'"
            />
            <img
              v-if="routerName('blog')"
              src="@/assets/svg/v2/articles-actif.svg"
              :alt="$t('commons.blog') + 'icon menu navigation'"
            />
            <p class="hide-min-tel">{{ $t('commons.blog') }}</p>
            <div :class="{ 'active-newsfeed': routerName('blog') }"></div>
          </li>

        </ul>
      </div>
    </div>

    <div class="bloc-actions">
      <a
        v-if="positionState != 'Login' && isNoAuth"
        @click="resetStateForgotPassword(), redirect('login')"
        class="hide-tel"
        >{{ $t('commons.login') }}</a
      >
      <a
        v-if="positionState != 'Register' && isNoAuth"
        @click="redirect('register')"
        class="hide-tel"
        >{{ $t('commons.registration') }}</a
      >

      <!-- <div class="alerts" v-if="!isNoAuth" @click.stop="openMenuAlerts()">
        <img src="@/assets/svg/v2/alert.svg" alt="Notifications">

        <div class="alerts-active" v-if="isOpenMenuAlerts">
          <div class="alerts-active-title">
            <p>{{ $t('commons.alerts') }}</p>
            <button>{{ $t('commons.alerts_clear') }}</button>
          </div>

          <div v-if="alerts.length > 0" class="alerts-active-body">
            <div v-for="(alert, n) in alerts" :key="n" class="alert">
              <div class="alert-image">
                <img v-if="alert.alert_type === 1" src="@/assets/svg/v2/matchs/matchsActive.svg" alt="Match (Buy)" class="match-buy" />
              </div>
              <div class="alert-content">
                <div>
                  <h4 v-if="alert.alert_type === 1">{{ $t('matchs.match_buy') }}</h4>
                  <p>{{ returnAlertDate(alert.created_at) }}</p>
                </div>
                <p v-if="alert.alert_type === 1" class="alert-match-title">{{ alert.title }}</p>
              </div>
            </div>
          </div>

          <div v-else class="alerts-active-empty">
            <img class="background" src="@/assets/svg/v2/home/background.svg" alt="TEP background" />
            <img src="@/assets/svg/v2/alertsEmpty.svg" alt="Empty alerts" width="70" />
            <p>{{ $t('commons.alerts_empty') }}</p>
          </div>
        </div>
      </div> -->

      <div class="lang-choice" @click="menuLangChoice = !menuLangChoice">
        <img class="lang-img" src="@/assets/svg/v2/lang.svg" alt="Lang icon menu navigation" />
        <span>{{ returnLanguageChoice.slice(0, 2).toUpperCase() }}</span>

        <el-drawer v-model="menuLangChoice" :title="$t('commons.lang.lang_choice')" :size="drawerSizeLang">
          <div class="lang-choice-active">  
            <button @click="closeMenu(), changeLang(lang)" v-for="(lang, b) in langs" :key="b" :class="{ activeLang: returnLanguageChoice === lang.slice(0, 2) }">
              <div class="flag-lang">
                <img
                  width="30"
                  height="20"
                  :src="require(`@/assets/svg/v2/flags/${lang.slice(0, 2)}.svg`)"
                  alt="flag of Germany lang choice"
                />
                {{ $t(`commons.lang.${lang.slice(0, 2)}_label`) }}
              </div>
              <img
                src="@/assets/svg/v2/checked.svg"
                alt="Checked"
                v-if="returnLanguageChoice === lang.slice(0, 2)"
              />
            </button>
          </div>
        </el-drawer>
      </div>

      <div @click.stop="displayCart = !displayCart" v-if="getCart.length > 0 && !isNoAuth" class="cart">
        <img src="@/assets/svg/v2/products/cart.svg" alt="Cart" />
        <div class="cart-orders-nb">
          {{ getCart.length }}
        </div>

        <el-drawer v-model="displayCart" title="" :size="drawerSize">
          <div class="cart-menu">
            <div v-for="(bottle, b) in getCart" :key="b" class="cart-product">
              <img :src="bottle.pictures[0]" alt="Product image" width="50" height="50">
  
              <div class="cart-product-text">
                <h4>{{ bottle.bottles[0].title }}</h4>
                <p>{{ bottle.price }} EUR</p>
              </div>

              <button class="cart-product-remove" @click="removeProduct(bottle)">
                <el-tooltip
                  :content="$t('payment_process.remove_to_cart')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img alt="Delete product" src="@/assets/svg/v2/delete.svg" />
                </el-tooltip>
              </button>
            </div>
          </div>

          <el-divider />

          <div class="cart-sub-total">
            <div class="order-payment-informations">
              <h3>{{ $t('payment_process.shipping_ups') }}</h3>
              <p>{{ packagesPrices }} EUR</p>
            </div>

            <div class="order-payment-informations">
              <h3>{{ $t('payment_process.inssurance_price') }}</h3>
              <p>{{ inssurancePrice }} EUR</p>
            </div>

            <div class="order-payment-informations">
              <h3>{{ $t('subscriptions.buy_commission') }} (2.08%)</h3>
              <p>{{ feesWithoutVAT }} EUR</p>
            </div>

            <div class="order-payment-informations">
              <h3>
                {{ $t('payment_process.payment_type_fees') }}
                <span v-if="paymentTypeFees">({{ paymentTypeFees.paymentFeesPercent }}%)</span>
                <span v-else>(- %)</span>
              </h3>
              <p v-if="paymentTypeFees">{{ paymentTypeFees.paymentFeesAmount }} EUR</p>
              <p v-else>0 EUR</p>
            </div>

            <div class="cart-payment-method-choice">
              <div @click="paymentMode = 'visa-mastercard'" class="visa-mastercard">
                <img
                  v-if="paymentMode === 'visa-mastercard'"
                  src="@/assets/svg/v2/dotActive.svg"
                  alt="Selected visa mastercard payment method"
                  class="active-inactive"
                />
                <img
                  v-else
                  src="@/assets/svg/v2/dot.svg"
                  alt="Inactive visa mastercard"
                  class="active-inactive"
                />
                <img
                  src="@/assets/svg/v2/subscriptions/visa.svg"
                  alt="Visa"
                />
                <img
                  src="@/assets/svg/v2/subscriptions/mastercard.svg"
                  alt="Mastercard"
                />
              </div>

              <div @click="paymentMode = 'amex'" class="amex">
                <img
                  v-if="paymentMode === 'amex'"
                  src="@/assets/svg/v2/dotActive.svg"
                  alt="Selected amex payment method"
                  class="active-inactive"
                />
                <img
                  v-else
                  src="@/assets/svg/v2/dot.svg"
                  alt="Inactive amex mastercard"
                  class="active-inactive"
                />
                <img
                  src="@/assets/svg/v2/subscriptions/ae.svg"
                  alt="AE"
                />
              </div>

              <div @click="paymentMode = 'paypal'" class="paypal">
                <img
                  v-if="paymentMode === 'paypal'"
                  src="@/assets/svg/v2/dotActive.svg"
                  alt="Selected paypal payment method"
                  class="active-inactive"
                />
                <img
                  v-else
                  src="@/assets/svg/v2/dot.svg"
                  alt="Inactive paypal mastercard"
                  class="active-inactive"
                />
                <img
                  src="@/assets/svg/v2/payment/paypal.svg"
                  alt="Paypal"
                />
              </div>

              <div @click="paymentMode = 'wallet'" class="wallet">
                <img
                  v-if="paymentMode === 'wallet'"
                  src="@/assets/svg/v2/dotActive.svg"
                  alt="Selected wallet payment method"
                  class="active-inactive"
                />
                <img
                  v-else
                  src="@/assets/svg/v2/dot.svg"
                  alt="Inactive wallet"
                  class="active-inactive"
                />
                <img
                  src="@/assets/svg/v2/wallet/wallet.svg"
                  alt="Wallet"
                />
                <p>{{ $t('commons.wallet') }}</p>
              </div>
            </div>

            <div class="order-payment-informations">
              <h3>{{ $t('subscriptions.buy_commission_tva') }} (20%)</h3>
              <p>{{ feesVAT }} EUR</p>
            </div>
          </div>

          <el-divider />

          <div class="cart-drawer-total">
            <div>
              <h4>{{ $t('subscriptions.total') }}</h4>
            </div>
            <h3 style="color: white">{{ cartTotal }} EUR</h3>
          </div>

          <template #footer="{  }">
            <div class="cart-footer">
              <button @click="paymentStep()" class="generic-btn">
                {{ $t('payment_process.buy') }}
              </button>
            </div>
          </template>

        </el-drawer>
      </div>

      <div class="user-menu" @click="openMenu()">
        <img
          v-if="!isOpenMenu"
          width="20"
          height="20"
          src="@/assets/svg/v2/menu.svg"
          alt="Menu"
        />
        <img
          v-else
          width="20"
          height="20"
          src="@/assets/svg/v2/menuActive.svg"
          alt="Menu is active"
        />
        <img
          v-if="!isOpenMenu"
          class="hide-min-tel"
          width="32"
          height="32"
          src="@/assets/svg/v2/account.svg"
          alt="Account"
        />
        <img
          v-else
          width="32"
          class="hide-min-tel"
          height="32"
          src="@/assets/svg/v2/accountActive.svg"
          alt="Account"
        />

        <el-drawer v-model="menuOpenConnectedUser" title="" :size="drawerSize">
          <div class="menu-user-login">
            <ul>
              <li
                @click.stop="closeMenu(), redirect('/auth/account/details')"
                class="menu-user-identity"
              >
                <div>
                  <img
                    src="@/assets/svg/v2/accountFirstLetters.svg"
                    alt="Complete names"
                  />
                  <p class="user-first-letters">
                    <img
                      v-if="client?.identity.account_type === 0"
                      src="@/assets/svg/v2/personnal.svg"
                      alt="Personnal"
                    />
                    <img v-else src="@/assets/svg/v2/pro.svg" alt="Pro" />
                  </p>
                </div>
                <div class="menu-user-identity-infos">
                  <p class="names">
                    <span v-if="client?.identity.account_type === 0"
                      >{{ client?.identity.firstname }}
                      {{ client?.identity.lastname.toUpperCase() }}</span
                    >
                    <span v-else>{{
                      client?.address.organization.name.toUpperCase()
                    }}</span>
                    <img
                      v-if="isAccountTypeSelected === 4"
                      class="success-img"
                      src="@/assets/svg/v2/registerProcess/success.svg"
                      alt="Success registration"
                      width="28"
                      height="28"
                    />
                  </p>
                  <p v-if="client?.identity.account_type === 0">
                    {{ $t('register.step_0.individual') }}
                  </p>
                  <p v-else>{{ $t('register.step_0.professionnal') }}</p>
                </div>
              </li>
              <li
                @click.stop="closeMenu(), redirect('/register')"
                v-if="isAccountTypeSelected < 4"
                class="registration-progress"
              >
                <div>
                  <div class="registration-progress-explain">
                    {{ $t('register.end_register') }}
                    <img
                      src="@/assets/svg/v2/arrowToRight.svg"
                      alt="Right arrow"
                    />
                  </div>
                  <div>
                    {{ $t('register.verify_account') }}
                  </div>
                </div>
                <div>
                  <img
                    v-if="isAccountTypeSelected === 1"
                    src="@/assets/svg/v2/accountStep/2.svg"
                    alt="Confirm email & phone"
                  />
                  <img
                    v-if="isAccountTypeSelected === 2"
                    src="@/assets/svg/v2/accountStep/3.svg"
                    alt="KYC"
                  />
                  <img
                    v-if="isAccountTypeSelected === 3"
                    src="@/assets/svg/v2/accountStep/4.svg"
                    alt="Banking informations"
                  />
                </div>
              </li>
  
              <el-divider class="menu-connect-divider" />
  
              <li class="li-dark-mode">
                <div>
                  <img src="@/assets/svg/v2/darkMode.svg" alt="Dark mode" />
                  {{ $t('commons.dark_mode') }}
                </div>
                <div class="switch-dark-mode">
                  <el-switch
                    :model-value="darkmode"
                    disabled
                    style="
                      --el-switch-on-color: #2667ff;
                      --el-switch-off-color: #333333;
                    "
                  />
                </div>
              </li>
  
              <!-- <el-divider class="menu-connect-divider" /> -->
  
              <li @click.stop="closeMenu(), redirect('/auth/order-alert')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/settings/notificationsActive.svg" alt="Orders alerts" />
                </div>
                {{ $t('commons.orders_alerts') }}
              </li>
              
              <li @click.stop="closeMenu(), redirect('/auth/order-list')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/settings/buySellActive.svg" alt="Live orders" />
                </div>
                {{ $t('commons.live_orders') }}
              </li>
  
              <li @click.stop="closeMenu(), redirect('/auth/transactions/history')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/transactions.svg" alt="Transactions history" />
                </div>
                {{ $t('commons.dashboard') }}
              </li>
  
  
              <li @click.stop="closeMenu(), redirect('/auth/account/wallet')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/wallet/wallet.svg" alt="Wallet" />
                </div>
                {{ $t('commons.my_wallet') }}
              </li>
  
              <li @click.stop="closeMenu(), redirect('/auth/account/details')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/settings.svg" alt="Settings" />
                </div>
                {{ $t('commons.account_settings') }}
              </li>
  
              <!-- <el-divider class="menu-connect-divider" /> -->
  
              <li @click.stop="closeMenu(), redirect('/subscriptions')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/settings/mySubscriptionsActive.svg" alt="Subscriptions" />
                </div>
                {{ $t('commons.subscriptions') }}
              </li>
  
              <!-- <el-divider class="menu-connect-divider" /> -->
  
              <li @click="closeMenu(), logout()" class="menu-li-action logout">
                <div>
                  <img src="@/assets/svg/v2/logout.svg" alt="Logout" />
                </div>
                {{ $t('commons.logout') }}
              </li>
            </ul>
          </div>
        </el-drawer>

        <el-drawer v-model="menuOpenDisconnectUser" title="" :size="drawerSize">
          <div class="menu-user-logout" @click="menuOpenDisconnectUser = !menuOpenDisconnectUser">
            <img
              width="130"
              src="@/assets/img/tep.webp"
              alt="The exchange platform - Logo"
              class="menu-logout"
            />

            <ul>
              <li @click.stop="closeMenu(), redirect('/register')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/register.svg" alt="Register" />
                </div>
                {{ $t('commons.registration') }}
              </li>

              <li @click.stop="closeMenu(), redirect('/login')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/login.svg" alt="Login" />
                </div>
                {{ $t('commons.login') }}
              </li>

              <!-- <el-divider class="menu-disconnect-divider" /> -->

              <li @click.stop="closeMenu(), redirect('/subscriptions')" class="menu-li-action">
                <div>
                  <img src="@/assets/svg/v2/settings/mySubscriptionsActive.svg" alt="Subscriptions" />
                </div>
                {{ $t('commons.subscriptions') }}
              </li>

              <!-- <el-divider class="menu-disconnect-divider" /> -->

              <li class="li-dark-mode">
                <div>
                  <img src="@/assets/svg/v2/darkMode.svg" alt="Dark mode" />
                  {{ $t('commons.dark_mode') }}
                </div>
                <div class="switch-dark-mode">
                  <el-switch
                    :model-value="darkmode"
                    disabled
                    style="
                      --el-switch-on-color: #2667ff;
                      --el-switch-off-color: #333333;
                    "
                  />
                </div>
              </li>
            </ul>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import m from 'moment'
import { findIndex } from 'lodash';

export default {
  props: {
    menuAlerts: {
      type: Boolean,
      required: false,
      default: true,
    },
    darkmode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      displayCart: false,
      drawerNavbar: false,
      menuOpenConnectedUser: false,
      menuOpenDisconnectUser: false,
      menuLangChoice: false,
      langs: ['de', 'en-GB', 'zh', 'ko', 'da', 'es', 'fr', 'el', 'hu', 'it', 'ja', 'pl', 'pt-PT', 'ru', 'sk', 'cs'],
      paymentMode: 'visa-mastercard',
      paymentModeCode: 3
    }
  },
  watch: {
    menuOpenConnectedUser() {
      if (this.menuOpenConnectedUser) {
        this.getClient()
      }
    },
    getCart: {
      deep: true,
      handler() {
        if (this.getCart.length === 0) {
          this.displayCart = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'getUserDetails',
      alerts: 'getAlerts',
      getAuthStatus: 'getIsAuth',
      getCart: 'getCart'
    }),
    isNoAuth() {
      return !this.$store.state.auth.auth && this.$store.state.auth.two_fa === 0
    },
    positionState() {
      return this.$route.name
    },
    isOpenMenu() {
      return this.menuOpenConnectedUser || this.menuOpenDisconnectUser
    },
    isOpenMenuLang() {
      return this.menuLangChoice
    },
    isOpenMenuAlerts() {
      return this.menuAlerts
    },
    returnLanguageChoice() {
      return this.$i18n.locale
    },
    isAccountTypeSelected() {
      return this.$store.state.auth.register_step - 1
    },
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '40%';
    },
    drawerSizeLang() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '30%';
    },
    packagesPrices() {
      let totalPackagesPrices = 0;

      this.getCart.forEach(b => {
        totalPackagesPrices += b.package_price;
      })

      return totalPackagesPrices;
    },
    inssurancePrice() {
      let totalInssurancePrice = 0;

      this.getCart.forEach(b => {
        totalInssurancePrice += b.insurance_price;
      })

      return totalInssurancePrice;
    },
    paymentTypeFees() {
      let totalPaymentTypeFees = {
        paymentFeesPercent: 0,
        paymentFeesAmount: 0,
        all: {
          visa_mastercard: 0,
          amex: 0,
          paypal: 0
        }
      };

      this.getCart.forEach(b => {
        totalPaymentTypeFees.all.visa_mastercard += b.payment_type_fees.visa_mastercard;
        totalPaymentTypeFees.all.amex += b.payment_type_fees.amex;
        totalPaymentTypeFees.all.paypal += b.payment_type_fees.paypal;

        if (this.paymentMode === 'visa-mastercard') {
          totalPaymentTypeFees.paymentFeesAmount += b.payment_type_fees.visa_mastercard;
          totalPaymentTypeFees.paymentFeesPercent = 2;
          this.paymentModeCode = 3;
        } else if (this.paymentMode === 'amex') {
          totalPaymentTypeFees.paymentFeesAmount += b.payment_type_fees.amex;
          totalPaymentTypeFees.paymentFeesPercent = 3;
          this.paymentModeCode = 6;
        } else if (this.paymentMode === 'paypal') {
          totalPaymentTypeFees.paymentFeesAmount += b.payment_type_fees.paypal;
          totalPaymentTypeFees.paymentFeesPercent = 3.5;
          this.paymentModeCode = 8;
        } else if (this.paymentMode === 'wallet') {
          this.paymentModeCode = 7;
        }
      })

      return totalPaymentTypeFees;
    },
    feesWithoutVAT() {
      let totalWithoutVAT = 0;

      this.getCart.forEach(b => {
        totalWithoutVAT += b.fees;
      })

      return totalWithoutVAT;
    },
    feesVAT() {
      let totalFeesVAT = 0;

      this.getCart.forEach(b => {
        totalFeesVAT += b.fees_vat;
      })

      return totalFeesVAT;
    },
    cartTotal() {
      const totalWithoutBottles = this.packagesPrices + this.inssurancePrice + this.paymentTypeFees.paymentFeesAmount + this.feesWithoutVAT + this.feesVAT;
      let totalWithBottles = 0;

      this.getCart.forEach(b => {
        totalWithBottles += b.price * b.quantity;
      })

      return parseFloat(totalWithoutBottles + totalWithBottles).toFixed(2);
    }
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      logoutAPI: 'logout',
      setLang: 'setLang',
    }),
    resetStateForgotPassword() {
      this.$store.commit('SET_FORGOT_PASSWORD', 0)
    },
    openMenu() {
      if (this.isNoAuth) {
        this.menuOpenDisconnectUser = !this.menuOpenDisconnectUser;
      } else {
        this.menuOpenConnectedUser = !this.menuOpenConnectedUser;
      }
    },
    closeMenu() {      
      this.menuOpenConnectedUser = false;
      this.menuOpenDisconnectUser = false;
      this.menuLangChoice = false;
    },
    openMenuAlerts() {
      this.$emit('menuStateAlerts')
    },
    redirect(path) {
      this.$router.push(path)
      this.$emit('menuState', 'x')
      this.$emit('menuStateLang', 'x')
      this.$emit('menuStateAlerts', 'x')
    },
    async logout() {
      await this.logoutAPI().then(() => {
        localStorage.removeItem('token')
        this.$store.commit('RESET_SEARCH', {
          key: 'searchProduct',
          products: [],
        })
        this.$store.commit('SET_AUTH', false)
        this.$store.commit('EMAIL_IS_CONFIRM', false)
        this.$store.commit('CHANGE_REGISTER_STEP', 1)
        this.$store.commit('CHANGE_2FA_STEP', 0)
        this.redirect('/');
      })
    },
    routerName(name) {
      return this.$route.name === name
    },
    changeLang(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang

      let doubleLang = null

      if (lang === 'en-GB') {
        doubleLang = 'en'
      } else if (lang === 'pt-PT') {
        doubleLang = 'pt'
      } else {
        doubleLang = lang
      }

      const payload = {
        code: doubleLang,
      }
      if (this.getAuthStatus) {
        this.setLang(payload);
        this.menuLangChoice = false;
      }
    },
    returnAlertDate(date) {
      return m(date).format('DD/MM/YYYY HH:mm')
    },
    paymentStep() {
      if (this.paymentModeCode) {
        const saveLS = {
          uuid: null,
          bottles: [],
          paymentMode: this.paymentModeCode,
          payment_type_fees: this.paymentTypeFees.all,
          cart: true,
          total: Number(this.cartTotal),
          fees: this.feesWithoutVAT,
          fees_vat: this.feesVAT,
          package_price: this.packagesPrices,
          insurance_price: this.inssurancePrice,
        }

        this.getCart.forEach(order => {
          order.bottles.forEach(b => {
            saveLS.bottles.push(b);
          })
        })

        localStorage.setItem('orderCart', JSON.stringify(saveLS));
        this.displayCart = false;
        this.$router.push('/order/payment');
      }
    },
    removeProduct(bottle) {
      const findIndexProduct = findIndex(this.getCart, (c) => {       
        return bottle.uuid === c.uuid;
      })
      
      if (findIndexProduct !== null) {        
        this.$store.commit('REMOVE_PRODUCT_CART', findIndexProduct);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.menu-user-identity {
  .img-text {
    display: flex;

    div {
      margin-right: 10px;
    }
  }
}

.nav-responsive-bottom {
  .menu-user-identity {
    padding-bottom: 0px;
  }
}

.nav-responsive-bottom .logout {
  color: #f43f5e;
}

.nav-responsive-bottom .registration-progress {
  background-color: $blue;
  border-radius: 8px;
  margin: 0 0px;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-drawer__body {
  background-color: #333 !important;
}

.nav-responsive-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.cart-drawer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-all-links {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nav-responsive-bottom .user-first-letters {
  margin-top: -38px;
  margin-left: 8px;
}

.nav-responsive-bottom ul {
  li {
    color: #fff;
    font-size: 16px;
    padding: 10px 0px 10px 0px;
    margin-bottom: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      border-radius: 6px;
      border: 1px solid $blue;
      background: $blue;
      color: white;
      padding: 6px;
    }
  }

  .li-dark-mode {
    img {
      margin-right: 8px;
    }

    .switch-dark-mode {
      margin-left: 51px;
    }
  }
}

.li-responsive {
  display: flex !important;
}

.nav-responsive ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
  font-size: 18px;
  margin-top: 10px;

  li {
    img {
      margin-right: 20px;
    }
  }
}

.cart-drawer-content {
  .cart-drawer-content-title {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 0;
      border-radius: 76px;
      border: 1px solid $border;
      background: #444;
      height: 38px;
      width: 38px;
    }
  }
}

.nav-top,
.bloc-actions,
.user-menu,
.lang-choice,
.bloc-logo-navigation,
.navigation-auth ul,
.navigation-auth ul li,
.li-dark-mode,
.li-dark-mode div,
.menu-user-identity,
.registration-progress-explain,
.registration-progress,
.flag-lang {
  display: flex;
}

.nav-top,
.user-menu,
.bloc-actions,
.navigation-auth ul li,
.li-dark-mode,
.li-dark-mode div,
.registration-progress-explain,
.registration-progress,
.flag-lang {
  align-items: center;
}

.flag-lang {
  gap: 3px;
}

.bloc-actions a,
.lang-choice {
  font-size: 15px;
}

.nav-top {
  height: 76px;
  background: #222222;
  color: #ffffff99;
  justify-content: space-between;
  padding-left: 66px;
  padding-right: 66px;
  border-bottom: 1px solid $border;

  .bloc-logo-navigation {
    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;
      min-width: 30px;
      margin-right: 14px;
    }

    .navigation-auth {
      ul {
        margin-left: 30px;

        li, .li-match {
          position: relative;
          margin-right: 15px;
          cursor: pointer;

          img {
            margin-right: 8px;
          }
        }
        
        .li-match {
          position: relative;
          
          .nav-alert-length {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            border-radius: 50%;
            background: #2667ffcf;
            color: white;
            width: 20px;
            height: 20px;
            top: -8px;
            right: -8px;
  
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .bloc-actions {
    a {
      color: #ffffff99;
      margin-right: 15px;
    }

    .lang-choice {
      position: relative;
      margin-left: 10px;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      .lang-choice-active {
        cursor: default;

        .flag-lang {
          color: white;
        }

        p {
          color: white;
          margin-bottom: 8px;
        }

        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 8px;
          border-radius: 8px;
          height: 38px;
          background: transparent;
          border: 0;
          color: $subtitle;
        }
      }
    }

    .user-menu {
      position: relative;
      background-color: $blue;
      border-radius: 21px;
      width: 72px;
      height: 40px;
      justify-content: space-evenly;
      margin-left: 10px;
      cursor: pointer;

      .menu-user-logout,
      .menu-user-login {
        cursor: default;

        .menu-logout {
          display: flex;
          margin: auto;
          margin-bottom: 25px;
        }

        ul {
          padding-bottom: 28px;

          li {
            color: #fff;
            font-size: 16px;
            padding: 8px 16px 8px 16px;
            margin-bottom: 6px;
            cursor: pointer;
          }

          .li-dark-mode {
            img {
              margin-right: 8px;
            }

            .switch-dark-mode {
              margin-left: 51px;
            }
          }
        }
      }

      .menu-user-login {
        height: max-content;

        ul {
          padding-bottom: 0;

          li {
            color: white;
          }

          .menu-user-identity {
            .menu-user-identity-infos {
              margin-left: 12px;

              .names {
                display: flex;
                justify-content: left;
                align-items: center;
              }

              p:nth-child(2) {
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }

          .registration-progress {
            background-color: $blue;
            border-radius: 8px;
            margin: 0 17px;
            padding: 8px 0;
            padding-left: 14px;

            div {
              .registration-progress-explain {
                margin-bottom: 7px;
                margin-top: 2px;
                margin-right: 10px;
              }

              div:nth-child(2) {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }

          .logout {
            color: #f43f5e;
            font-weight: 500;
          }
        }
      }
    }

    .alerts {
      position: relative;
      margin-top: 5px;
      cursor: pointer;

      .alerts-active {
        overflow: hidden;
        cursor: default;
        position: absolute;
        right: 0;
        top: 40px;
        border-radius: 12px;
        border: 1px solid $border;
        background: #333;
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.16);
        width: 393px;
        height: 358px;
        padding: 22px 0;

        .alerts-active-title {
          display: flex;
          justify-content: space-between;
          padding: 0 16px;

          button {
            background: transparent;
            border: 0;
            color: $grey40;
            z-index: 1;
          }
        }

        .alerts-active-body {
          margin-top: 30px;

          .alert {
            display: flex;
            align-items: center;
            border-radius: 12px;
            cursor: pointer;
            padding: 5px 8px;
            margin: 0 8px;

            .alert-image {
              display: flex;
              justify-content: center;
              align-items: center;
              background: $blue;
              width: 42px;
              height: 39px;
              border-radius: 50%;
              margin-right: 11px;
            }

            .alert-content {
              display: flex;
              flex-direction: column;

              div {
                display: flex;
                justify-content: space-between;

                h4 {
                  color: white;
                  font-size: 16px;
                  font-weight: 600;
                }
              }

              .alert-match-title {
                margin-top: 5px;
                font-weight: 500;
                font-size: 15px;
              }
            }
          }

          .alert:hover {
            background: #d9d9d929;
          }
        }

        .alerts-active-empty {
          position: relative;
          height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .background {
            position: absolute;
            width: 700px;
            right: -160px;
            top: -50px;
            z-index: 0;
          }

          p {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .menu-li-action {
    display: flex;
    align-items: center;
    padding-left: 0!important;

    div {
      display: flex;
      justify-content: center;
      width: 35px;
    }
  }

  .menu-disconnect-divider,
  .menu-connect-divider {
    margin: 8px auto;
  }

  .menu-user-identity:nth-child(1) {
    position: relative;

    .user-first-letters {
      position: absolute;
      top: 16px;
      left: 14px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .active-trading:before {
    content: ' ';
  }

  .active-portfolio,
  .active-newsfeed,
  .active-matchs,
  .active-matchs-alerts,
  .active-trading {
    position: absolute;
    top: 45px;
    height: 2px;
    width: 100%;
    border-bottom: 2px solid $blue;
  }

  .active-portfolio:before,
  .active-newsfeed:before {
    content: ' ';
  }
}

.menu-user-identity {
  padding-left: 5px!important;
}

.li-dark-mode {
  justify-content: space-between;
  padding-left: 5px!important;
}

.cart {
  position: relative;
  margin: auto 10px;
  margin-right: 5px;
  margin-bottom: 0;
  cursor: pointer;
  
  .cart-orders-nb {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    border-radius: 50%;
    background: #2667ffcf;
    color: white;
    width: 20px;
    height: 20px;
    top: -10px;
    right: -7px;
  }
  
  .cart-menu {

    .cart-product {
      display: flex;
      margin-bottom: 8px;
    }

    img {
      border-radius: 4px;
    }

    .cart-product-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      width: 100%;

      h4, p {
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        margin-left: 8px;
      }
      
      h4 {
        width: 100%;

      }

      p {
        min-width: 75px;
        text-align: right;
        margin-left: 0;
        font-weight: 400;
      }
    }

    .cart-product-remove {
      background: transparent;
      border: none;
      margin-left: 8px;
    }
  }
}

.cart-footer {
  display: flex;
  justify-content: center;
  
  button {
    max-width: 200px;
  }
}

.cart-sub-total {
  margin-top: 36px;

  .order-payment-informations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 15px;

    h3 {
      font-size: 17px;
      font-weight: 500;
    }

    p {
      text-align: right;
      margin-left: 20px;
      min-width: 90px;
    }
  }
}

.cart-payment-method-choice {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .visa-mastercard, .amex, .paypal, .wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid $border;
    border-radius: 3px;
    color: white;

    img {
      margin-left: 5px;
    }

    .active-inactive {
      width: 15px;
    }

    p {
      margin-left: 5px;
    }
  }
}

.cart-drawer-total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 42px;
  
  div {
    
    h4 {
      color: white;
      font-size: 20px;
      font-weight: 500;
    }

    p {
      color: $grey40;
      font-size: 14px;
      font-weight: 500;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
}

.activeLang {
  background: #555 !important;
  color: white !important;
  font-weight: 600 !important;
}

.hide-min-tel {
  flex-wrap: nowrap;
  color: #ffffff;
}

.button-burger {
  display: none;

  img {
    width: 35px;
  }
}

@media screen and (max-width: 1090px) {
  .nav-top {
    padding: 0 40px;

    .bloc-actions {
      .hide-tel {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 975px) {
  .active-portfolio,
  .active-trading,
  .hide-min-tel,
  .active-newsfeed {
    display: none;
  }

  .li-responsive .hide-min-tel {
    display: block !important;
  }

  .li-responsive {
    display: flex !important;
  }
}

@media screen and (max-width: 720px) {
  .li-responsive {
    display: flex !important;
  }

  .li-responsive img {
    display: block !important;
  }

  .nav-top {
    padding: 0 5px;
    height: 60px;

    .bloc-logo-navigation {
      .logo {
        margin-right: 4px;

        img {
          width: 60px;
        }
      }
    }
  }

  .button-burger {
    display: flex;
  }

  .user-menu {
    width: 35px !important;
    height: 35px!important;
    margin-left: 5px !important;
  }

  .lang-choice {
    margin-left: 0 !important;

    .lang-img {
      display: none;
    }
  }

  .navigation-auth {
    ul {
      margin-left: 10px !important;

      li {
        margin-right: 10px !important;

        img {
          margin-right: 0 !important;
        }
      }
    }
  }

  .nav-responsive {
    display: flex !important;
  }
}
</style>
